<template>
  <div class="text-center" v-if="permission.read_perm === 1">
    <v-dialog
      v-model="dialog"
      :fullscreen="wWidth < 780"
      max-width="80%"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <p
          id="archiveAdd"
          v-if="param.act === 'add'"
          v-bind="attrs"
          v-on="on"
          @click="add"
        ></p>
        <p id="archiveEdit" v-else v-bind="attrs" v-on="on" @click="update"></p>
      </template>
      <div style="position:relative;">
        <v-container style="background: white; border-radius: 5px;">
          <v-form
            ref="entryForm"
            @submit.prevent="submit"
            style="position: relative"
          >
            <v-card
              flat
              style="
                z-index: 2;
                position: sticky;
                top: 0;
                background: white;
                border-bottom: 1px solid black;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div
                style="
                  display: flex;
                  jsutify-content: center;
                  align-items: center;
                  margin-left: 10px;
                "
              >
                <v-toolbar-title class="overline">
                  <p
                    v-if="wWidth > 780"
                    style="font-size: 20px; margin: 0; font-weight: bold"
                  >
                    Formulir Pengelolaan Dokumen
                  </p>
                  <p
                    v-else
                    style="font-size: 14px; margin: 0; font-weight: bold"
                  >
                    Formulir Pengelolaan Dokumen
                  </p>
                </v-toolbar-title>
              </div>
              <div class="doc-form-btn-1">
                <div>
                  <v-btn
                    type="submit"
                    rounded
                    outlined
                    small
                    elevation="1"
                    color="indigo"
                    class="indigo--text"
                    style="font-size:12px;margin-right:10px;"
                    :loading="loading"
                  >
                    simpan
                  </v-btn>
                </div>

                <v-btn
                  rounded
                  outlined
                  small
                  elevation="1"
                  color="red"
                  class="red--text"
                  style="font-size:12px;"
                  @click="close"
                  :loading="loading"
                >
                  Batal
                </v-btn>
              </div>
              <v-btn
                v-if="wWidth < 780"
                icon
                small
                color="red"
                class="red--text"
                style="font-size:12px;"
                @click="close"
                :loading="loading"
              >
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
            </v-card>

            <v-row style="padding: 15px">
              <v-col
                cols="12"
                md="5"
                style="padding: 0 10px;display:flex;justify-content:center;align-items:center;"
              >
                <!-- <div style="padding: 0 10px;width:100%;"> -->
                <v-col
                  cols="7"
                  style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                >
                  <p
                    style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Dokumen dapat kadaluarsa ?
                  </p>

                  <v-checkbox
                    v-model="form.has_expire"
                    style=" margin:0 0 0 10px; height:25px; padding:0;"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="5"
                  style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                >
                  <p
                    style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Private ?
                  </p>

                  <v-checkbox
                    v-model="form.is_private"
                    style=" margin:0 0 0 10px; height:25px; padding:0;"
                  ></v-checkbox>
                </v-col>
                <!-- </div> -->
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Nama Dokumen
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.name"
                      dense
                      outlined
                      :rules="docNameRules"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Jenis Dokumen
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      v-model="form.document_type_id"
                      :items="[{ id: 4, name: 'Document Department' }]"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      style="margin: 0"
                      disabled
                    >
                    </v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kategori
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      v-model="form.category_id"
                      :items="getDropdownDocCategory"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      style="margin: 0"
                      :rules="categoryRules"
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      No Dokumen
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-model="form.doc_no"
                      dense
                      outlined
                      style="margin: 0"
                      :rules="docNoRules"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kepemilikan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      v-model="form.plant_id"
                      :items="getDropdownPlant"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      style="margin: 0"
                      :rules="locationRules"
                      disabled
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Rak Index
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-model="form.rack_index"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Department
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      v-model="form.pic_department_id"
                      :items="dropdown.department"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      dense
                      style="margin: 0"
                      :rules="picRules"
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tgl Terbit
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0"
                    ><v-text-field
                      v-model="form.doc_date"
                      clearable
                      outlined
                      dense
                      type="date"
                      name="date"
                      step="1"
                      :rules="docDateRules"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      v-if="form.has_expire"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tgl Expired
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-if="form.has_expire"
                      v-model="form.doc_expiry_date"
                      clearable
                      outlined
                      dense
                      type="date"
                      name="date"
                      step="1"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Jumlah
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-model="form.doc_qty"
                      type="number"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Satuan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      v-model="form.doc_qty_unit_id"
                      :items="getDropdownGlobalUnit"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      style="margin: 0"
                    ></v-select>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Penerbit
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-model="form.published_by"
                      dense
                      outlined
                      :rules="publishedByRules"
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kota
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-model="form.published_city"
                      dense
                      outlined
                      style="margin: 0"
                      :rules="publishedCityRules"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Status
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      v-model="form.doc_status"
                      :items="getDropdownDocStatus"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      dense
                      style="margin: 0"
                      :rules="statusRules"
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0"
                    ><p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Upload File
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-file-input
                      ref="docUpload"
                      @change="uploadFile"
                      :label="form.file !== '' ? form.file : '- browse file -'"
                      prepend-icon=""
                      outlined
                      dense
                    ></v-file-input>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="12" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Keterangan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-textarea
                      v-model="form.description"
                      dense
                      auto-grow
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
            </v-row>

            <div class="doc-form-btn-2">
              <div style="padding: 0 10px;">
                <v-btn
                  type="submit"
                  :loading="loading"
                  rounded
                  elevation="1"
                  color="indigo"
                  class="white--text"
                  style="font-size:14px;width:100%;"
                >
                  simpan
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-container>
        <div
          v-if="isUpload"
          class="d-flex justify-center align-center"
          style="position:absolute;top:0;z-index:3; left:0;width:100%; height:100%;background:rgba(0,0,0,0.2);"
        >
          <v-progress-circular
            :size="150"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  name: 'Archive-Form',
  props: ['param', 'permission'],
  data() {
    return {
      wWidth: window.innerWidth,
      eSanqua: buildType.apiURL('esanqua'),
      upload: buildType.apiURL('upload2'),
      download: buildType.apiURL('download'),
      hrsApi: buildType.apiURL('hrs'),
      dialog: false,
      form: {},
      rules: {},
      loading: false,
      select: null,
      search: null,
      items: [],
      new: [],

      docTypeRules: [],
      docNameRules: [],
      docNoRules: [],
      docDateRules: [],
      locationRules: [],
      picRules: [],
      publishedCityRules: [],
      publishedByRules: [],
      statusRules: [],
      categoryRules: [],
      isUpload: false,
      dropdown: {
        department: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getDropdownCompany',
      'getDropdownPlant',
      'getDropdownGlobalUnit',
      'getDropdownDocStatus',
      'getDropdownDocCategory'
    ])
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    'form.plant_id'() {
      if (this.form.plant_id !== '') {
        this.dropdownDepartment()
      }
    }
  },
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clear() {
      this.docTypeRules = []
      this.docNameRules = []
      this.docNoRules = []
      this.docDateRules = []
      this.locationRules = []
      this.picRules = []
      this.publishedByRules = []
      this.publishedCityRules = []
      this.statusRules = []
      this.categoryRules = []
      this.form = {}
      this.form.file = ''
      // reset file input
      this.$refs.docUpload.reset()
      this.$emit('loadPage')
    },
    clickOutside() {
      this.clear()
    },
    close() {
      this.dialog = false
      this.clear()
    },
    add() {
      const date = new Date()
      const splitDate = date.toLocaleDateString('id').split('/')
      if (splitDate[1].length === 1) {
        splitDate[1] = '0' + splitDate[1]
      }
      const todayDate = splitDate.reverse().join('-')
      this.form = {
        name: '',
        doc_no: '',
        document_type_id: 4,
        has_expire: false,
        is_private: 0,
        plant_id: Number(this.getUserProfile.employee.company.plant_id),
        location_department_id: '',
        pic_department_id: {
          id: Number(this.getUserProfile.employee.department.id),
          name: this.getUserProfile.employee.department.name
        },
        doc_date: todayDate,
        doc_expiry_date: '',
        rack_index: '-',
        description: '',
        doc_qty: '1',
        doc_qty_unit_id: 2,
        published_by: '-',
        published_city: '-',
        file: '',
        doc_status: { id: 1, name: 'Available' },
        category_id: ''
      }
    },
    update() {
      const a = true
      const b = false
      axios
        .get(`${this.eSanqua}archive_document/detail/${this.param.items.id}`)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.form = {
              name: res.data.data.name,
              doc_no: res.data.data.doc_no,
              document_type_id: res.data.data.document_type.id,
              has_expire: res.data.data.has_expire === 1 ? a : b,
              is_private: res.data.data.is_private,
              plant_id:
                res.data.data.plant !== null
                  ? Number(res.data.data.plant.id)
                  : '',
              location_department_id: {
                id: res.data.data.location_department_id,
                name: res.data.data.location_department_name
              },
              pic_department_id: {
                id: res.data.data.pic_department_id,
                name: res.data.data.pic_department_name
              },
              doc_date: this.filterDate(res.data.data.doc_date),
              doc_expiry_date: this.filterDate(res.data.data.doc_expiry_date),
              rack_index: res.data.data.rack_index,
              description: res.data.data.description,
              doc_qty: res.data.data.doc_qty,
              doc_qty_unit_id:
                res.data.data.doc_qty_unit !== null
                  ? res.data.data.doc_qty_unit.id
                  : '',
              published_by: res.data.data.published_by,
              published_city: res.data.data.published_city,
              doc_status: res.data.data.doc_status,
              file: res.data.data.file,
              category_id:
                res.data.data.category !== null ? res.data.data.category.id : ''
            }
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    submit() {
      this.docTypeRules = [v => !!v || 'Document Type is required']
      this.docNameRules = [v => !!v || 'Document Name is required']
      this.docNoRules = [v => !!v || 'Document Number is required']
      this.docDateRules = [v => !!v || 'Document Published Date is required']
      this.locationRules = [v => !!v || 'Owned by is required']
      this.picRules = [v => !!v || 'PIC is required']
      this.publishedByRules = [v => !!v || 'Published By is required']
      this.publishedCityRules = [v => !!v || 'Published City is required']
      this.statusRules = [v => !!v || 'Status is required']
      this.categoryRules = [v => !!v || 'Category is required']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const type = this.form.has_expire
      const newForm = {
        name: this.form.name,
        doc_no: this.form.doc_no,
        document_type_id: this.form.document_type_id,
        has_expire: !type ? 0 : 1,
        plant_id: this.form.plant_id,
        // location_department_id: this.form.location_department_id.id,
        // location_department_name: this.form.location_department_id.name,
        pic_department_id: this.form.pic_department_id.id,
        pic_department_name: this.form.pic_department_id.name,
        doc_date: this.form.doc_date,
        doc_expiry_date: type ? this.form.doc_expiry_date : '',
        rack_index: this.form.rack_index !== '' ? this.form.rack_index : '-',
        description: this.form.description,
        doc_qty: this.form.doc_qty,
        doc_qty_unit_id: this.form.doc_qty_unit_id,
        published_by: this.form.published_by,
        published_city: this.form.published_city,
        doc_status_id: this.form.doc_status.id,
        file: this.form.file,
        category_id: this.form.category_id
      }

      if (this.param.act === 'update') {
        if (this.param.duplicate) {
          Object.assign(newForm, {
            act: 'add'
          })
        } else {
          Object.assign(newForm, {
            act: this.param.act,
            id: this.param.items.id
          })
        }
      } else {
        Object.assign(newForm, {
          act: this.param.act
        })
      }
      if (newForm.has_expire === 0) {
        delete newForm.doc_expiry_date
      }
      this.save(newForm)
    },
    save(form) {
      console.log(form)
      this.loading = true
      axios
        .post(`${this.eSanqua}archive_document/department_document/save`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            // if (this.param.act === 'add') {
            this.close()
            // } else {
            this.$emit('loadPage')
            // }
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    uploadFile(event) {
      if (event !== null) {
        this.isUpload = true
        const data = new FormData()
        data.append('file', event)
        axios
          .post(`${this.upload}esanqua/archive_document/sop`, data)
          .then(res => {
            console.log(res)
            if (res.data.status === true) {
              this.showMsgDialog('success', res.data.message, false)
              this.form.file = res.data.data.name
            } else if (res.data.status === false) {
              this.showMsgDialog('warning', res.data.message, false)
            } else {
              this.showMsgDialog('warning', res.data.message, false)
            }
            this.isUpload = false
          })
          .catch(err => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
            this.isUpload = false
          })
      } else {
        this.form.file = ''
      }
    },
    filterDate(date) {
      const day = date.slice(0, date.indexOf('-'))
      const month = date.slice(date.indexOf('-') + 1, date.lastIndexOf('-'))
      const year = date.slice(date.lastIndexOf('-') + 1)
      return year + '-' + month + '-' + day
    },
    saveDuplicate() {
      this.form.document_type_id = 2
      this.submit()
    },
    async dropdownDepartment() {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.form.plant_id}}]`
        )
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.arch-add-new-btn {
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}
.doc-form-btn-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.doc-form-btn-2 {
  display: none;
}

@media (max-width: 780px) {
  .doc-form-btn-1 {
    display: none;
  }
  .doc-form-btn-2 {
    display: block;
  }
}
</style>
